import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { useStaticQuery, graphql } from "gatsby"

import PageWrapper from "../components/PageWrapper"
import Section from "../components/Section"
import Container from "../components/Container"
import InnerHero from "../components/InnerHero"
import BlogCard1 from "../components/blogs/BlogCard1"
import Pagination from "../components/Pagination"

const BlogPageNoSidebar = ({ data }) => {
  const { image2, image3, image4, image5 } = useStaticQuery(graphql`
    query {
      image2: allFile(filter: { relativePath: { eq: "blog-card-2.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image3: allFile(filter: { relativePath: { eq: "blog-card-3.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image4: allFile(filter: { relativePath: { eq: "blog-card-4.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image5: allFile(filter: { relativePath: { eq: "blog-card-5.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasTop: true,
          hasBottomSocial: true,
          bottomClassName: "bg-primary-25",
          className: "!bg-primary-50",
        }}
      >
        <GatsbySeo
          title={`Kloft - Blog No Sidebar`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <InnerHero
          title="Blog No Sidebar"
          text="Resources to speed up your product growth."
          className=""
        ></InnerHero>

        <Section>
          <Container>
            {/* wrap */}

            {/* right-part */}
            <div className="flex flex-col gap-16 laptop:gap-24">
              <div className="flex flex-col gap-11">
                <div className="grid grid-cols-1 mx-auto laptop:grid-cols-3 gap-11">
                  <BlogCard1
                    title="Why do your customers become unhappy?"
                    text="Proactively fabricate one-to-one materials via effectiv business."
                    imageData={image2.nodes[0]}
                    tag="Technology"
                    date="Feb 27, 2021"
                  ></BlogCard1>
                  <BlogCard1
                    title="Why are your customers and why unhappy?"
                    text="Objectively pursue diverse catalysts for change for interoperable meta-services."
                    imageData={image3.nodes[0]}
                    tag="Freelancing"
                    date="Feb 22, 2021"
                  ></BlogCard1>
                  <BlogCard1
                    title="Our experiences moving to remote"
                    text="Proactively fabricate one-to-one materials via effectiv business..."
                    imageData={image4.nodes[0]}
                    tag="Business"
                    date="Feb 20, 2021"
                  ></BlogCard1>
                  <BlogCard1
                    title="Boost your customer base with these 7 tips"
                    text="Objectively pursue diverse catalysts for change for interoperable meta..."
                    imageData={image5.nodes[0]}
                    tag="Education"
                    date="Aug 10, 2021"
                  ></BlogCard1>
                  <BlogCard1
                    title="The 5 essential metrics for customer success"
                    text="Dramatically engage top-line web services vis-a-vis cutting-edge deliverables."
                    imageData={image2.nodes[0]}
                    tag="Marketing"
                    date="Jan 15, 2021"
                  ></BlogCard1>
                  <BlogCard1
                    title="Why are your customers and why unhappy?"
                    text="Objectively pursue diverse catalysts for change for interoperable meta-services."
                    imageData={image3.nodes[0]}
                    tag="Freelancing"
                    date="Feb 22, 2021"
                  ></BlogCard1>
                </div>
              </div>
              {/* pajination */}
              <Pagination />
            </div>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default BlogPageNoSidebar
