import React from "react"
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons"
import { Link } from "gatsby"

import Button from "./Button"

const Pagination = ({ children, className = "" }) => {
  return (
    <>
      <div className={`flex items-center gap-[2px] tablet:gap-1 ${className}`}>
        <Link to="#">
          <Button
            className="px-4 !bg-transparent !shadow-none hover:text-primary-600 border-none !text-center text-neutral-700 "
            size="lg"
            iconRight={false}
            icon={<ArrowLeft />}
          >
            Prev
          </Button>
        </Link>
        <Link to="#">
          <Button
            className="text-black  !text-center hover:text-neutral-50 !bg-neutral-100 hover:!bg-primary-600 !border border-neutral-300 h-9 w-9 tablet:w-10 tablet:h-10 !px-0 !py-0"
            iconClass="hidden"
          >
            1
          </Button>
        </Link>
        <Link to="#">
          <Button
            className="text-black hidden tablet:block !text-center hover:text-neutral-50 bg-white !border border-neutral-300 h-10 w-10 !px-0 !py-0"
            iconClass="hidden"
          >
            2
          </Button>
        </Link>
        <Link to="#">
          <Button
            className="text-black hidden tablet:block !text-center hover:text-neutral-50 bg-white !border border-neutral-300 h-10 w-10 !px-0 !py-0"
            iconClass="hidden"
          >
            3
          </Button>
        </Link>
        <Link to="#">
          <Button
            className="text-black  !text-center hover:text-neutral-50 bg-white !border border-neutral-300 h-9 w-9 tablet:w-10 tablet:h-10  !px-0 !py-0"
            iconClass="hidden"
          >
            ...
          </Button>
        </Link>
        <Link to="#">
          <Button
            className="text-black hidden tablet:block !text-center hover:text-neutral-50 bg-white !border border-neutral-300 h-10 w-10 !px-0 !py-0"
            iconClass="hidden"
          >
            8
          </Button>
        </Link>
        <Link to="#">
          <Button
            className="text-black hidden tablet:block !text-center hover:text-neutral-50 bg-white !border border-neutral-300 h-10 w-10 !px-0 !py-0"
            iconClass="hidden"
          >
            9
          </Button>
        </Link>
        <Link to="#">
          <Button
            className="text-black  !text-center hover:text-neutral-50 bg-white !border border-neutral-300 h-9 w-9 tablet:w-10 tablet:h-10  !px-0 !py-0"
            iconClass="hidden"
          >
            10
          </Button>
        </Link>
        <Link to="#">
          <Button
            className="px-4 !bg-transparent !shadow-none hover:text-primary-600 border-none !text-center text-neutral-700"
            size="lg"
            icon={<ArrowRight />}
          >
            Next
          </Button>
        </Link>
      </div>
    </>
  )
}
export default Pagination
